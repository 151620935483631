<template>
  <form class="dropzone" :class="[type, getFileName, getStatus, { mockFile: value && fileName }]">
    <!-- Not displayed, just for Dropzone's `dictDefaultMessage` option -->
    <div id="dropzone-message" style="display: none">
      <div id="dropzone-current" class="current">
        <template v-if="$route.name.includes('assessment')">
          <span class="no-badge-image">No File uploaded</span>
        </template>
        <template v-else>
          <span class="no-badge-image">No badge image selected</span>
          <span class="pending-badge-image">A badge is pending upload</span>
        </template>
      </div>
      <span class="dropzone-title">
        <span v-if="type === 'hotspot' || type === 'assessment'" id="dropzone-label" class="text upload-badge-image"
          >Upload File <IconUpload class="upload-icon"
        /></span>
        <span v-else id="dropzone-label" class="text upload-badge-image"
          >Upload Badge Image <IconUpload class="upload-icon"
        /></span>
      </span>
    </div>
  </form>
</template>

<script>
import Dropzone from 'dropzone';
// import 'dropzone/dist/dropzone.css';
import IconUpload from '../assets/icons/upload_icon.svg';

export default {
  name: 'dropzone',
  components: {
    IconUpload,
  },
  props: {
    type: String,
    imageWidth: Number,
    imageHeight: Number,
    value: [String, Boolean],
    fileName: [String, Boolean],
    acceptedFiles: {
      type: String,
      default: 'image/png, .png',
    },
  },
  data() {
    return {
      label: 'Upload Badge Image',
      initialEvent: null,
      uploadKey: null,
      error: null,
      files: [],
      mockFile: '',
      mockEnabled: '',
      addedFile: '',
    };
  },
  computed: {
    getFileName() {
      return this.addedFile.name ? `${this.addedFile.name.split('.')[0]}-image` : '';
    },
    getStatus() {
      return this.addedFile ? 'image-uploaded' : '';
    },
  },
  methods: {
    cancel() {
      this.dropzone.removeAllFiles(true);
    },
    saveAndUpload() {
      this.$emit('accepted', {
        file: this.cachedFile,
        dropzone: this.dropzone,
      });
    },
  },
  mounted() {
    const vm = this;

    window.URL = window.URL || window.webkitURL;
    Dropzone.autoDiscover = false;
    const options = {
      url: '/',
      method: 'put',
      sending(file, xhr) {
        const { send } = xhr;
        // eslint-disable-next-line no-param-reassign
        xhr.send = () => {
          send.call(xhr, file);
        };
      },
      hiddenInputContainer: this.$el,
      parallelUpload: 1,
      maxFiles: 1,
      uploadMultiple: false,
      acceptedFiles: vm.acceptedFiles,
      // header: '',
      dictDefaultMessage: document.querySelector('#dropzone-message').innerHTML,
      autoProcessQueue: false,
      accept(file, done) {
        vm.$emit('accepted', file);
        setTimeout(() => {
          vm.saveAndUpload();
        }, 300);
        done();
      },
      previewTemplate: `
        <div class="dz-preview dz-file-preview">
          <div class="dz-wrapper">
            <div class="current">
              <img data-dz-thumbnail />
            </div>
            <div class="dz-remove" data-dz-remove>
            <div class="dz-filename"><span data-dz-name></span></div>
              <svg xmlns="http://www.w3.org/2000/svg" width="10.757" height="10.757" viewBox="0 0 10.757 10.757">
                <g id="x_icon" transform="translate(-2.121 -2.121)">
                  <line id="Line_1" data-name="Line 1" y1="9.343" x2="9.343" transform="translate(2.828 2.828)" fill="none" stroke="#ff335c" stroke-linecap="round" stroke-width="1"/>
                  <line id="Line_2" data-name="Line 2" x2="9.343" y2="9.343" transform="translate(2.828 2.828)" fill="none" stroke="#ff335c" stroke-linecap="round" stroke-width="1"/>
                </g>
              </svg>
              </div
            </div>
          </div>
        </div>`,
    };

    this.dropzone = new Dropzone(this.$el, options);

    this.dropzone.on('thumbnail', (file) => {
      this.addedFile = file;
      vm.cachedFile = file;
    });

    this.dropzone.on('addedfile', (file) => {
      this.addedFile = file;
      vm.cachedFile = file;
    });

    this.dropzone.on('processing', (file) => {
      this.dropzone.options.url = file.uploadUrl;
    });

    this.dropzone.on('maxfilesexceeded', (file) => {
      this.dropzone.removeAllFiles(true);
      this.dropzone.addFile(file);
    });

    this.dropzone.on('success', (file) => {
      if (file.status === 'success') {
        this.dropzone.disable();
        this.$emit('ended', true);
      }
    });

    this.dropzone.on('error', () => {
      this.$emit('ended', false);
    });

    this.dropzone.on('removedfile', () => {
      this.addedFile = '';
      this.$emit('clear');
    });

    const self = this;
    setTimeout(() => {
      if (self.value) {
        self.mockFile = { name: self.fileName || '', size: 1234, accepted: true };
        self.dropzone.emit('addedfile', self.mockFile);
        self.dropzone.emit('success', self.mockFile, self.mockFile.name || '');
        self.dropzone.emit('thumbnail', self.mockFile, self.value.split('?')[0]);
        self.dropzone.emit('complete', self.mockFile);
        self.dropzone.files.push(self.mockFile);
      }
    }, 500);
  },
};
</script>

<style lang="scss" scoped>
.current {
  color: rgba(0, 0, 0, 0.5);
}
.dz-hidden-input {
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 0;
  visibility: hidden;
}
form.dropzone {
  min-height: initial;
  padding: 0;
  margin: 0;
  cursor: pointer;
  border: 0;

  &:hover {
    .dz-message {
      .dropzone-title .text {
        opacity: 1;
      }
    }
  }
  .dz-message {
    position: relative;
    span.dropzone-title {
      position: absolute;
      top: -3px;
      right: 0;
      display: block;
      // color: $primary;
      // line-height: 30px;
      white-space: nowrap;
      cursor: pointer;

      .text {
        margin-left: 5px;
        font-family: 'VWText Bold', $fallback-font-stack;
        font-size: 14px;
        text-transform: capitalize;
        vertical-align: middle;
        transition: opacity 200ms;
      }

      svg {
        display: inline-block;
        width: 14px;
        height: 13px;
        vertical-align: middle;
        fill: currentColor;
        stroke: currentColor;
      }
    }
    span.dropzone-info {
      position: absolute;
      top: 0;
      left: 0;
      color: #898989;
    }
  }

  &.dz-started {
    border-bottom-color: transparent;
  }

  ::v-deep {
    .dz-button {
      padding: 0;
      font-family: inherit;
      color: #00b0f0;
      background: none;
      border: 0;
    }
  }
  ::v-deep .dz-preview {
    cursor: default;

    .dz-wrapper {
      display: flex;
      align-items: center;
    }
    .dz-filename {
      &.assessment {
        margin-right: auto;
      }
    }
    .dz-remove {
      display: flex;
      // color: $magenta;
      align-items: center;
      width: calc(100% - 151px);
      font-size: 12px;
      font-weight: bold;
      line-height: 30px;
      text-transform: uppercase;
      // margin-left: auto;
      // vertical-align: middle;
      // position: absolute;
      // top: 0;
      // right: 0;

      &.assessment {
        &::before {
          display: none;
        }
      }
      &::before {
        // background: url('../assets/icons/x_icon_red.svg?external') no-repeat center center;
        display: inline-block;
        width: 12px;
        height: 12px;
        margin-right: 12px;
        vertical-align: -0.0625em;
        content: '';
      }
    }

    .dz-filename span {
      margin-right: 8px;
      font-family: 'VWTEXT LIGHT', $fallback-font-stack;
      font-size: 14px;
      font-weight: normal;
      line-height: 17px;
      text-transform: initial;
      letter-spacing: 0;
    }

    &.dz-complete {
      .dz-progress {
        opacity: 0;
      }
    }

    .dz-error-message {
      color: $red;
    }
  }

  .upload-badge-image {
    display: inline-block;
  }
  .upload-new-image {
    display: none;
  }
  #dropzone-current {
    display: inline-block;
    .no-badge-image {
      display: inline-block;
    }
    .pending-badge-image {
      display: none;
    }
  }

  &.image-uploaded,
  &.mockFile {
    #dropzone-current,
    .upload-badge-image {
      display: none;
    }
    .upload-new-image {
      display: inline-block;
    }
    .dropzone-title {
      top: 20px !important;
    }
  }

  &.pending-image {
    .upload-badge-image {
      display: inline-block;
    }
    .upload-new-image {
      display: none;
    }
    #dropzone-current {
      display: inline-block;
      .no-badge-image {
        display: none;
      }
      .pending-badge-image {
        display: inline-block;
      }
    }
    .dropzone-title {
      top: -1px !important;
    }
    ::v-deep {
      .dz-preview {
        display: none;
      }
    }
  }
}
.modal-crop {
  ::v-deep .modal {
    box-sizing: border-box;
    width: 100%;
    height: 100%;

    .modal-header {
      padding-bottom: 0;
      margin: -35px -35px 0;
      background-color: $primary-black;

      button {
        margin: 0;
        background: none;
        border: 0;
        outline: 0;

        &.icon {
          padding: 21px;

          svg {
            display: block;
            width: 18px;
            height: 18px;
          }
        }
      }
    }
    .modal-body {
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      .modal-crop-left {
        position: relative;
        flex-grow: 0;
        width: 100%;
        max-height: 50vh;

        &.profile {
          .cropper-view-box {
            border-radius: 50%;
          }
        }
      }
      .modal-crop-right {
        position: relative;
        display: none;
        flex: 0 0 270px;
        width: 270px;
        margin-left: 50px;

        img {
          width: 100%;
        }

        .crop-wrapper {
          position: relative;
        }
        .error {
          margin-top: 25px;
          color: #eb3323;
        }
      }
    }
  }
}
.upload-icon {
  width: 10px;
  height: 9px;
}
.dropzone {
  position: relative;
  &.profile {
    margin: 16px 0 10px 0;
    ::v-deep {
      .dropzone-title {
        svg {
          width: 10px;
          height: 9px;
        }
      }
      .current {
        font-size: 14px;
        img {
          width: 64px;
          height: 62px;
          border-radius: 50%;
        }
        .dropzone-title {
          font-size: 14px;
        }
      }
    }
  }
  &.cover {
    ::v-deep {
      .current {
        img {
          width: 100%;
        }
      }
    }
  }
  .no-upload {
    font-size: 14px;
    opacity: 0.5;
  }
}
::v-deep {
  .red {
    margin-left: 5px;
    font-family: 'VWText Bold', $fallback-font-stack;
    font-size: 14px;
    color: $warning-red;
    text-transform: none;
  }
}
</style>
